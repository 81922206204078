<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Role </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Push Master to Tenant</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Select Tenant</label>
                               <v-autocomplete
                                  v-model="tenant"
                                  :items="tenantlist"
                                  item-text="tc_name"
                                  item-value="tc_id"
                              ></v-autocomplete>`
                            <v-btn color="success" @click="addRole">Push Data</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>
                 <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Sr. No.</th>
                        <th class="text-left">Super Saster</th>
                        <th class="text-left">To</th>
                        <th class="text-left">Master</th>
                        <th class="text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in statuslist" :key="item.name">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.super_master_name }}</td>
                        <td><v-icon style="color:blue;">mdi-arrow-right-bold-circle</v-icon></td>
                        <td>{{ item.master_name }}</td>
                        <td>
                            <span v-if="item.status"><v-icon style="color:green; "> mdi-checkbox-marked-circle</v-icon></span>
                            <span v-else><v-icon style="color:red;">mdi-close-circle</v-icon></span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            tenant:null,
            tenantlist:null,
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            rules: {
                required: (value) => !!value || "Required.",
            },
            
            statuslist:null,
        }),
        watch: {},
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
           
            onload() {
                this.overlay = true
                axios
                    .post("/SuperAdmin/getTenantdata")
                    .then((res) => {
                        this.tenantlist = res.data.tc_list;
                        this.overlay = false
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            addRole() {
                const data = {
                    tenant: this.tenant,
                };
                this.overlay=true
                if (this.tenant) {
                    axios
                        .post("/SuperAdmin/pusMaster", data)
                        .then((res) => {
                            this.org = res.data.org;
                            this.Role_list = res.data.role_list;
                            if (res.data.msg == "success") {
                                this.overlay=false
                                this.showSnackbar("#4CAF50", "Data Push successfully...");
                                this.statuslist=res.data.list1
                            } else {
                                this.overlay=false
                                this.showSnackbar("#b71c1c", res.data.msg);
                                this.$refs.form.validate()
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please Select Tenant");
                    this.$refs.form.validate()
                }
            },
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
